import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './index.css';
import spinner from './waitmist.gif'; // Add your spinner image path

function RegistrationForm() {
    const prefix = 'BEAUTYREST_BEAUTYREST';
    const [step, setStep] = useState(1);
    const [employeeId, setEmployeeId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    const [shopifyStoreUrl, setShopifyStoreUrl] = useState('');
    const [isEmployeeIdValidated, setIsEmployeeIdValidated] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const apiKey = '44c3e9c48c9e4286bdeeb094c3d8522e';
    const headers = {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': apiKey,
    };
    const validateEmployee = 'https://hospitality-employee.azure-api.net/hospitality-lookupId-employee/hospitalityLookupIdEmployee';
    const registerEmployee = 'https://hospitality-employee.azure-api.net/hospitality-register-employee/hospitalityRegisterEmployee';

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const storeUrl = urlParams.get('shopifyStore');
        if (storeUrl) {
            setShopifyStoreUrl(storeUrl);
        }
    }, []);

    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isValidPhone = (phone) => /^\d{10}$/.test(phone);

    const validateEmployeeId = async () => {
        setLoading(true); // Start loading
        try {
            const response = await axios.post(validateEmployee, { prefix, employeeId }, { headers });
            const responseBody = response.data;

            if (responseBody.valid) {
                setStep(2);
                setIsEmployeeIdValidated(true);
                setError('');
            } else if (responseBody.exists) {
                setError('This employee is already registered with a Shopify customer account.');
            } else {
                setError('Invalid Employee ID. Please check and try again.');
            }
        } catch (error) {
            setError('Error validating Employee ID. Please try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const submitRegistration = async () => {
        if (!isValidEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        if (!isValidPhone(phone)) {
            setError('Please enter a valid 10-digit US phone number.');
            return;
        }
        setLoading(true); // Start loading
        try {
            const response = await axios.post(registerEmployee, { prefix, employeeId, firstName, lastName, email, phone }, { headers });
            const responseBody = response.data;

            if (responseBody.success) {
                setStep(3);
            } else {
                setError('Error registering the account. Please try again.');
            }
        } catch (error) {
            setError('Error submitting the registration. Please try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="registration-form">
            <div className="header">
                <img
                    src="https://ssbhospitality.myshopify.com/cdn/shop/files/Logo.svg?v=1683050888&width=60"
                    alt="Serta Hospitality Logo"
                    className="logo"
                />
                <h1>Serta Hospitality Employee Registration Form</h1>
            </div>

            <div className="stepper" aria-label="Registration steps">
                <div className={`step ${step >= 1 ? 'active' : ''}`} aria-label="Step 1: Verify Employee ID"></div>
                <div className={`step ${step >= 2 ? 'active' : ''}`} aria-label="Step 2: Enter Account Details"></div>
                <div className={`step ${step >= 3 ? 'active' : ''}`} aria-label="Step 3: Registration Complete"></div>
            </div>

            {loading && (
                <div className="loading-container">
                    <img src={spinner} alt="Loading..." className="spinner" />
                </div>
            )}

            {!loading && step === 1 && (
                <div>
                    <h2>Enter Valid Employee ID</h2>
                    <label htmlFor="employeeId">Employee ID:</label>
                    <input
                        type="text"
                        id="employeeId"
                        className="input-field"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                        required
                    />
                    <button className="submit-button" onClick={validateEmployeeId}>
                        Validate Employee ID
                    </button>
                    {error && <p className="error-message">{error}</p>}
                </div>
            )}

            {!loading && step === 2 && isEmployeeIdValidated && (
                <div>
                    <h2>Enter Additional Information</h2>
                    <label htmlFor="firstName">First Name:</label>
                    <input
                        type="text"
                        id="firstName"
                        className="input-field"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                    <label htmlFor="lastName">Last Name:</label>
                    <input
                        type="text"
                        id="lastName"
                        className="input-field"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        className="input-field"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor="phone">Phone:</label>
                    <input
                        type="tel"
                        id="phone"
                        className="input-field"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                    <button className="submit-button" onClick={submitRegistration}>
                        Submit Registration
                    </button>
                    {error && <p className="error-message">{error}</p>}
                    <button className="exit-button" onClick={() => window.top.location.href = shopifyStoreUrl}>
                        Cancel
                    </button>
                </div>
            )}

            {!loading && step === 3 && (
                <div>
                    <h2>Registration Complete!</h2>
                    <p>Please check your email for further instructions on how to activate your employee account.</p>
                    <button className="exit-button" onClick={() => window.top.location.href = shopifyStoreUrl}>
                        Exit
                    </button>
                </div>
            )}
        </div>
    );
}

export default RegistrationForm;
